<template>
  <div>
    <!-- 设置设备参数 -->
    <a-modal title="控制设备" v-model="visible" @ok="handleOk">
      <div class="property-name">
        <div>{{property.name}}</div>
        <a-tag color="green"> {{property.identifier}}</a-tag>
      </div>


      <a-input v-if="isTypeInput" placeholder v-model="value"/>

      <a-select v-else style="width: 100%" v-model="value">
        <template v-for="(value,key) in property.dataType.specs">
          <a-select-option :value="key">{{key}} - {{value}}</a-select-option>
        </template>
      </a-select>

    </a-modal>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        value: '',
        property: {},
        onSetPara:null,
        isTypeInput:true
      }
    },
    methods: {
      show (property,onSetPara) {
        this.property = property
        if(property.value){
          this.value = property.value+""
        }else{
          this.value=""
        }

        let type = property.dataType.type
        this.isTypeInput = !(type=="bool" || type == "enum")

        this.onSetPara = onSetPara

        this.visible = true
      },
      handleOk () {
        if(this.onSetPara){
          this.onSetPara(this.value)
        }
        this.visible=false
      }
    }
  }
</script>

<style scoped>
  .property-name {
    font-size: 20px;
    margin-bottom: 20px;
  }
</style>
